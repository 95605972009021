import { mapGetters } from 'vuex'
import { permissao, verifica } from '@/components/getMenu.js'

export default {
  computed: {
    ...mapGetters([
      'menu'
    ])
  },
  data () {
    return {
      PERMISSION: [],
      ACTION: {
        c: false,
        e: false,
        d: false,
        cc: false,
        ec: false,
        ca: false,
        ah: false,
        ev: false,
        av: false,
        vc: false,
        vr: false,
        v: false
      }
    }
  },
  methods: {
    getAcesso (acesso) {
      if (!acesso.length) {
        this.$router.push('/')
      } else {
        this.PERMISSION = acesso.map(i => i.submenu)[0].map(i => i.actions)[0]
      }
      this.getPermission()
    },
    getPermission () {
      this.ACTION.c = verifica(this.PERMISSION, 'Cadastrar')
      this.ACTION.e = verifica(this.PERMISSION, 'Editar')
      this.ACTION.d = verifica(this.PERMISSION, 'Excluir')
      this.ACTION.cc = verifica(this.PERMISSION, 'Cadastrar Cliente')
      this.ACTION.ec = verifica(this.PERMISSION, 'Editar Cliente')
      this.ACTION.ca = verifica(this.PERMISSION, 'Cadastrar Agenda')
      this.ACTION.ah = verifica(this.PERMISSION, 'Agenda Historico')
      this.ACTION.cv = verifica(this.PERMISSION, 'Cadastrar Vendas')
      this.ACTION.ev = verifica(this.PERMISSION, 'Editar Vendas')
      this.ACTION.av = verifica(this.PERMISSION, 'Editar Vendas')
      this.ACTION.vc = verifica(this.PERMISSION, 'Contrato Vendas')
      this.ACTION.vr = verifica(this.PERMISSION, 'Recibo Vendas')
      this.ACTION.v = verifica(this.PERMISSION, 'Visualizar')
    }
  },
  created () {
    let dados = JSON.parse(localStorage.getItem('_menu'))
    const acesso = permissao(dados, this.$route.meta.pai, this.$route.meta.path)
    this.getAcesso(acesso)
  }
}
