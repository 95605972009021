const permissao = (lista, titulo, url) => {
  const menu = lista.slice()
  return menu.filter(i => {
    if (i.titulo === titulo) {
      i.submenu = i.submenu.filter(z => {
        if (z.url === url) {
          if (z.actions && z.actions.length) {
            z.actions = z.actions.filter(x => {
              if (x.value === true) {
                return x
              }
            })
          }
          return z
        }
      })
      return i
    }
  }).map(i => i)
}

const verifica = (lista, tipo) => {
  return lista.filter(i => i.name === tipo && i.value).length
}

export { permissao, verifica }
